<template>
  <svg viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_604_2371)">
      <path d="M15 8V16H5V8H15ZM16 6H4C3.45 6 3 6.45 3 7V17C3 17.55 3.45 18 4 18H16C16.55 18 17 17.55 17 17V13.5L21 17.5V6.5L17 10.5V7C17 6.45 16.55 6 16 6Z"/>
    </g>
    <defs>
      <clipPath id="clip0_604_2371">
        <rect width="24" height="24"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "VideocamIcon",
  props: {
    color: {
      type: String,
      default: "inherit"
    },
  }
}
</script>

<style scoped lang="scss">
svg {
  vertical-align: top;
  width: 2.4rem;
  height: auto;
}
</style>
