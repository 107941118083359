import {boot} from 'quasar/wrappers'
import UiButton from "components/ui/UiButton.vue";

export default boot(({app}) => {
  const requireComponent = [
    require.context(
      "src/components/icons",
      true,
      /[A-Z-_]\w+\.(vue)$/
    ),
    require.context(
      "src/components/page-blocks",
      true,
      /[A-Z-_]\w+\.(vue)$/
    ),
  ];

  requireComponent.forEach(directory => directory.keys().forEach((fileName) => {
    const componentConfig = directory(fileName);
    const componentName = fileName
      .split("/")
      .pop()
      .replace(/\.\w+$/, "");

    app.component(componentName, componentConfig.default || componentConfig);
  }))

  app.component('ui-button', UiButton);
})
