
const routes = [
  {
    path: '/auth',
    component: () => import('layouts/AuthLayout.vue'),
    meta: {
      permissions: ['guest']
    },
    children: [
      { path: 'login', name: 'login-page', component: () => import('pages/authorization/LogInPage.vue') },
      { path: 'signup', name: 'signup-page', component: () => import('pages/authorization/SignUpPage.vue') },
    ]
  },
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: 'index-page', component: () => import('pages/IndexPage.vue') },
      { path: 'support', name: 'support-page', component: () => import('pages/SupportPage.vue') },
    ]
  },

  // <---------------------------------OWNER AREA-------------------------------------------------->
  {
    path: '/',
    meta: {
      permissions: ['owner']
    },
    component: () => import('layouts/OwnerLayout.vue'),
    children: [
      { path: 'projects', name: 'projects-page', component: () => import('pages/ProjectsPage.vue') },
      { path: 'settings', name: 'profile-settings', component: () => import('pages/ProfileSettingsPage.vue') },
    ]
  },
  {
    path: '/projects/:projectName',
    meta: {
      permissions: ['owner']
    },
    component: () => import('layouts/ProjectLayout.vue'),
    children: [
      { path: '', name: 'project-index-page', component: () => import('pages/ProjectIndexPage.vue') },
      { path: 'settings', name: 'project-settings', component: () => import('pages/ProjectSettingsPage.vue') },
      { path: ':pageId', name: 'edit-page', component: () => import('pages/EditPage.vue') },
      { path: 'users', name: 'users', component: () => import('pages/UsersPage.vue') },
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    name: '404',
    component: () => import('pages/ErrorNotFound.vue')
  }
]

export default routes
