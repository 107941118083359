import {boot} from "quasar/wrappers";
import {useUserStore} from "stores/user-store";
import {Cookies} from "quasar";
import {useProjectsStore} from "stores/projects-store";

const userStore = useUserStore();
const projectStore = useProjectsStore();
export default boot( async () => {

  if (Cookies.has('ep_user_token')) {
    await userStore.auth(Cookies.get('ep_user_token'));
    await projectStore.getProjects().then((res) => {
      res.data.forEach(p => {
        projectStore.addProject(p);
      })
    });
  }
});
