import { defineStore } from 'pinia';
import {api} from "boot/axios";

export const usePagesStore = defineStore('pages', {
  state: () => ({
    loading: false,
    pages: new Map(),
  }),

  getters: {
  },

  actions: {
    getPages(projectId) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        try {
          api.get(`api/client/project/${projectId}/page`).then(res => {
            res.data.forEach(p => {
              if (!p.content?.blocks) p.content = {blocks: []};
              this.addPage(projectId, p);
            })
            resolve(res);
          }).catch((err) => {
            reject(err);
          }).finally(() => {
            this.loading = false;
          })
        } catch (e) {
          reject(e)
        }
      })
    },
    addPage(projectId, page) {
      const oldPages = this.pages.get(projectId) ? this.pages.get(projectId) : [];
      this.pages.set(projectId, [...oldPages, page]);
    },
    createPage(projectId, data) {
      return new Promise((resolve, reject) => {
        try {
          api.post(`api/client/project/${projectId}/page`, data).then(res => {
            if (!res.data.blocks) res.data.blocks = [];
            this.addPage(projectId, res.data);
            resolve(res);
          }).catch((err) => {
            reject(err);
          })
        } catch (e) {
          reject(e)
        }
      })
    },
    editPage(projectId, pageId, data) {
      return new Promise((resolve, reject) => {
        try {
          api.patch(`api/client/project/${projectId}/page/${pageId}`, data).then(res => {
            resolve(res);
          }).catch((err) => {
            reject(err);
          })
        } catch (e) {
          reject(e)
        }
      })
    }
  }
})
