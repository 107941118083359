<template>
  <ui-subdrawer title="Редактирование" v-model="isOpen">
    <template v-slot:body>
      <q-form
        class="form"
        @submit="onSubmit"
      >

        <h3 class="h3 form__name">{{blockInfo?.description}}</h3>

        <div class="form__inputs">
          <slot></slot>
        </div>

        <div class="form__actions">
          <ui-button
            class="btn btn_secondary btn_md"
            text="Отменить" type="button"
            @click="cancelChanges"
          />
          <ui-button
            class="btn btn_primary btn_md"
            text="Сохранить" type="submit"
          />
        </div>
      </q-form>
    </template>
  </ui-subdrawer>
</template>
<script setup>
import {ref} from "vue";
import UiSubdrawer from "components/drawers/UiSubdrawer";

const emit = defineEmits(['cancel-changes', 'submit-changes']);
const props = defineProps({
  blockInfo: {
    type: Object,
    default: () => {return {}},
  }
})

const isOpen = ref(false);

function openDrawer() {
  isOpen.value = true;
}
function closeDrawer() {
  isOpen.value = false;
}

function onSubmit() {
  emit('submit-changes')
}
function cancelChanges() {
  emit('cancel-changes')
}

defineExpose({
  openDrawer, closeDrawer
})

</script>

<style scoped lang="scss">
.form {
  &__name {
    margin-bottom: 16px;
  }
  &__inputs {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 12px;
  }
  &__actions {
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    > .btn {
      width: 100%;
    }
  }
}
.selector {
  cursor: pointer;
  margin-bottom: 20px;
}
.drawer {
  width: 460px;

  &__content {
    padding: 24px;
  }

  &__divider {
    margin: 24px 0;
    border-bottom: 1px solid var(--borderColor);
  }
}
</style>
