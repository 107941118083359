<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_181_3965)">
      <path d="M11.99 18.54L4.62 12.81L3 14.07L12 21.07L21 14.07L19.37 12.8L11.99 18.54ZM12 16L19.36 10.27L21 9L12 2L3 9L4.63 10.27L12 16ZM12 4.53L17.74 9L12 13.47L6.26 9L12 4.53Z"/>
    </g>
    <defs>
      <clipPath id="clip0_181_3965">
        <rect width="24" height="24"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "layersIcon"
}
</script>

<style scoped lang="scss">
svg {
  vertical-align: top;
  width: 2.4rem;
  height: auto;
}
</style>
