<template>
  <svg viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_629_2524)">
      <path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"/>
    </g>
    <defs>
      <clipPath id="clip0_629_2524">
        <rect width="24" height="24"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "DeleteIcon",
  props: {
    color: {
      type: String,
      default: "inherit"
    },
  }
}
</script>

<style scoped lang="scss">
svg {
  vertical-align: top;
  width: 2.4rem;
  height: auto;
}
</style>
