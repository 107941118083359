import { defineStore } from 'pinia';
import {api} from "boot/axios";
import {usePagesStore} from "stores/pages-store";

const pagesStore = usePagesStore();

export const useProjectsStore = defineStore('projects', {
  state: () => ({
    loading: false,
    currentProjectId: 1,
    projects: [
    ]
  }),

  getters: {
    currentProject: (state) => {
      // if (!state.projects.length && state.currentProjectId) return {}
      return state.projects.find(p => p.id === state.currentProjectId)
    },
  },

  actions: {
    setCurrentProject(id) {
      this.currentProjectId = id;
      pagesStore.getPages(id);

      this.currentProject.settings.colors.forEach(color => {
        document.documentElement.style.setProperty(color.id, color.hex);
      })
    },
    getProjects() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        try {
          api.get('api/client/project').then(res => {
            this.loading = false;
            resolve(res);
          }).catch((err) => {
            this.loading = false;
            reject(err);
          })
        } catch (e) {
          reject(e)
        }
      })
    },
    createProject(data) {
      return new Promise((resolve, reject) => {
        try {
          api.post('api/client/project', data).then(res => {
            this.addProject(res.data);
            resolve(res);
          }).catch((err) => {
            reject(err);
          })
        } catch (e) {
          reject(e)
        }
      })
    },
    addProject(project) {
      this.projects.unshift(project)
    },
    deleteProject(projectId) {
      return new Promise((resolve, reject) => {
        try {
          api.delete(`api/client/project/${projectId}`).then(res => {
            // delete project
            const index = this.projects.findIndex(p => p.id === projectId);
            if (index > -1) this.projects.splice(index, 1);

            // set new current project
            if (this.projects[0]) this.currentProjectId = this.projects[0].id;
            else this.router.push({name: 'index-page'})
            resolve(res);
          }).catch((err) => {
            reject(err);
          })
        } catch (e) {
          reject(e)
        }
      })
    },
    editProject(projectId, data) {
      return new Promise((resolve, reject) => {
        try {
          api.patch(`api/client/project/${projectId}`, data).then(res => {

            resolve(res);
          }).catch((err) => {
            reject(err);
          })
        } catch (e) {
          reject(e)
        }
      })
    },
  }
})
