<template>
  <svg viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_181_3866)">
      <path d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H6L4 18V4H20V16Z"/>
    </g>
    <defs>
      <clipPath id="clip0_181_3866">
        <rect width="24" height="24"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "ChatBubble",
  props: {
    color: {
      type: String,
      default: "inherit"
    },
  }
}
</script>

<style scoped lang="scss">
svg {
  vertical-align: top;
  width: 2.4rem;
  height: auto;
}
</style>
