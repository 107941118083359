<template>
  <q-btn :class="props.class" unelevated v-bind="restProps">
    <slot v-if="slot.default"></slot>
    <template v-else>
      <p v-if="props.text">{{props.text}}</p>
      <component v-if="props.icon" :is="props.icon"/>
    </template>
  </q-btn>
</template>

<script setup>
import {computed, useSlots} from "vue";

const slot = useSlots();
const props = defineProps({
  class: {type: String, default: 'btn'},
  text: {type: String, default: ''},
  icon: {type: String, default: ''},
})
const restProps = computed(() => {
  const {class: v1, text: v2, icon: v3, ...rest} = props;
  return rest
})
</script>

<style scoped lang="scss">

</style>
