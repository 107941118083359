<template>
  <div class="block">
    <h1 class="h1">{{ blockInfo.text }}</h1>
  </div>

  <Teleport to="#subdrawers" v-if="isMounted">
    <EditBlockDrawer
      ref="editBlockDrawer"
      :block-info="blockInfo"
      @submit-changes="onSubmit"
      @cancel-changes="cancelChanges"
    >
      <q-input
        outlined
        hide-bottom-space
        v-model="blockInfo.text"
        label="Текст"
        class="form__input"
      />
    </EditBlockDrawer>
  </Teleport>
</template>

<script setup>
import EditBlockDrawer from "components/drawers/EditBlockDrawer";
import {computed, onMounted, ref} from "vue";

const emit = defineEmits(['update:model-value', 'save', 'cancel-changes'])
const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {
      return {}
    },
  }
})

const blockInfo = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:model-value', value)
  }
});

const editBlockDrawer = ref(null);
const isMounted = ref(false);

let startInfo = null;

function openDrawer() {
  startInfo = JSON.parse(JSON.stringify(blockInfo.value));
  editBlockDrawer.value.openDrawer();
}

function closeDrawer() {
  startInfo = null;
  editBlockDrawer.value.closeDrawer();
}

onMounted(() => {
  isMounted.value = true
})

function cancelChanges() {
  blockInfo.value.text = startInfo.text;
}

function onSubmit() {
  emit('save')
  closeDrawer();
}

defineExpose({
  openDrawer, closeDrawer, blockInfo
})
</script>

<style scoped lang="scss">
</style>
