<template>
  <div
    ref="drawerRef"
    class="drawer" :class="{'active': isOpen}"
  >
    <div class="drawer__content">
      <div class="drawer__head">
        <h2 class="h2 drawer__name">{{props.title}}</h2>
        <button class="drawer__close-btn btn" @click="hide">
          <close-icon/>
        </button>
      </div>

      <div class="drawer__body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, ref, watch} from "vue";

const emit = defineEmits(['update:model-value'])
const props = defineProps({
  title: {type: String, default: ''},
  modelValue: {type: Boolean, default: false},
});

const drawerRef = ref(null);

const isOpen = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:model-value', value);
  }
})

watch(isOpen, (val) => {
  if (val) setTimeout(() => {document.addEventListener("mousedown", documentClickHandler)}, 0);
  else document.removeEventListener("mousedown", documentClickHandler);
})

function toggle() {
  if (isOpen.value) {
    hide();
  } else {
    show();
  }
}
function show() {
  isOpen.value = true;
  setTimeout(() => {
    document.addEventListener("mousedown", documentClickHandler)
  }, 0)
}
function hide() {
  isOpen.value = false;
  document.removeEventListener("mousedown", documentClickHandler)
}

const documentClickHandler = (evt) => {
  if (evt.target.closest('.drawer')) {
    return
  }
  if (evt.target !== document.documentElement) {
    hide();
  }
}

defineExpose({
  show, hide, toggle,
})
</script>

<style scoped lang="scss">
.drawer {
  width: 484px;
  pointer-events: none;
  transform: translateX(0);
  transition: var(--transition);
  opacity: 0;

  position: absolute;
  z-index: 1;
  top: 0;

  height: 100%;
  overflow: auto;

  background-color: var(--bodyBgColor);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);

  &.active {
    pointer-events: auto;
    transform: translateX(-100%);
    opacity: 1;
  }

  &__content {
    height: 100%;
  }

  &__head {
    position: sticky;
    top: 0;

    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: inherit;
    color: var(--bodyPrimaryTypeColor);
    fill: var(--bodyPrimaryTypeColor);
  }

  &__body {
    padding: 0 24px 24px 24px;
  }
}
</style>
