<template>
  <div class="block">
    <q-carousel
      v-if="blockInfo.images.length > 1"
      v-model="slide"
      arrows navigation
      animated swipeable infinite
      transition-prev="slide-right"
      transition-next="slide-left"
      padding
      control-color="primary"
      height="600px"
    >
      <q-carousel-slide
        v-for="(img, idx) in blockInfo.images" :key="idx"
        :name="idx+1"
        class="column no-wrap"
      >
        <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap">
          <q-img class="rounded-borders full-height" :src="img"/>
        </div>
      </q-carousel-slide>
    </q-carousel>
    <q-img
      v-else
      :src="blockInfo.images[0]"
      spinner-color="white"
      fit="contain"
      style="max-width: 100%; height: 600px;"
    />
  </div>

  <Teleport to="#subdrawers" v-if="isMounted">
    <EditBlockDrawer
      ref="editBlockDrawer"
      :block-info="blockInfo"
      @submit-changes="onSubmit"
      @cancel-changes="cancelChanges"
    >
      <div
        class="form__input-wrapper"
        v-for="(img, idx) in blockInfo.images" :key="idx"
      >
        <ui-button
          class="form__delete-btn btn btn_md btn_secondary"
          icon="delete-icon"
          @click="deleteImage(idx)"
        />
        <q-input
          outlined
          hide-bottom-space
          :model-value="img"
          @update:model-value="(value) => changeValue(idx, value)"
          label="URL картинки"
          class="form__input"
        />
      </div>

      <ui-button
        class="form__btn btn btn_secondary btn_md"
        text="Добавить"
        @click="addImage"
      />
    </EditBlockDrawer>
  </Teleport>
</template>


<script setup>
import EditBlockDrawer from "components/drawers/EditBlockDrawer";
import {computed, onMounted, ref} from "vue";

const emit = defineEmits(['update:model-value', 'save', 'cancel-changes'])
const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {
      return {}
    },
  }
})

const blockInfo = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:model-value', value)
  }
});

const editBlockDrawer = ref(null);

const slide = ref(1)

function addImage() {
  blockInfo.value.images.push('https://img-cdn.inc.com/image/upload/w_1920,h_1080,c_fill/images/panoramic/getty_1016968886_pdrdaj.jpg')
}
function changeValue(idx, value) {
  blockInfo.value.images[idx] = value;
}
function deleteImage(idx) {
  blockInfo.value.images.splice(idx, 1);
}

function cancelChanges() {
  blockInfo.value.images = startInfo.images;
}

function onSubmit() {
  emit('save')
  closeDrawer();
}

const isMounted = ref(false);
onMounted(() => {
  isMounted.value = true
})

let startInfo = null;
function openDrawer() {
  startInfo = JSON.parse(JSON.stringify(blockInfo.value));
  editBlockDrawer.value.openDrawer();
}

function closeDrawer() {
  startInfo = null;
  editBlockDrawer.value.closeDrawer();
}

defineExpose({
  openDrawer, closeDrawer, blockInfo
})
</script>

<style scoped lang="scss">
:deep(.text-primary) {
  color: var(--mainColor) !important;
}
.form {
  &__input-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
  &__delete-btn {
    width: auto;
  }
  &__input {
    flex-grow: 1;
  }

  &__btn {
    width: 100%;
  }
}
</style>
