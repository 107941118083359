import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import routes from './routes'
import {useUserStore} from "stores/user-store";

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const userStore = useUserStore();

  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE)
  })

  Router.beforeEach((to, from) => {
    if (!to.meta.permissions) return;

    // if route for guest
    if (to.meta.permissions.indexOf('guest') !== -1) {
      if (userStore.isAuthenticated) return from
    }

    // if route for project owner
    if (to.meta.permissions.indexOf('owner') !== -1) {
      if (userStore.user.post !== 'Admin') {
        if (!userStore.isAuthenticated) return {name: 'login-page'}
        else return from
      }
    }
  })

  return Router
})
