<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_603_4535)">
      <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "AddIcon"
}
</script>

<style scoped lang="scss">
svg {
  vertical-align: top;
  width: 2.4rem;
  height: auto;
}
</style>
