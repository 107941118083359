import { defineStore } from 'pinia';
import {api} from "boot/axios";
import {Cookies} from "quasar";
import {useRouter} from "vue-router";

const router = useRouter();

export const useUserStore = defineStore('user', {
  state: () => ({
    user: {},
    token: null,
  }),

  getters: {
    isAuthenticated: (state) => !!state.user.id,
  },
  actions: {
    register(data) {
      return new Promise((resolve, reject) => {
        try {
          api.post('api/auth/client/signup', data).then(res => {
            this.setToken(res.data.token);
            resolve(res);
          }).catch((err) => {
            reject(err);
          })
        } catch (e) {
          reject(e)
        }
      })
    },
    login(data) {
      return new Promise((resolve, reject) => {
        try {
          api.post('api/auth/client/signin', data).then(res => {
            this.setToken(res.data.token);
            resolve(res);
          }).catch((err) => {
            reject(err);
          })
        } catch (e) {
          reject(e)
        }
      })
    },
    auth(token) {
      this.setToken(token);
      return new Promise((resolve, reject) => {
        try {
          api.get('api/auth/user').then(res => {
            this.setUser(res.data);
            resolve(res);
          }).catch((err) => {
            reject(err);
          })
        } catch (e) {
          reject(e)
        }
      })
    },
    logout() {
      this.user = null;
      Cookies.remove('ep_user_token');

      delete api.defaults.headers.common['authorization'];
      router.push({name: 'login-page'})
    },
    setToken(token) {
      api.defaults.headers.common['authorization'] = 'Bearer ' + token;
      Cookies.set('ep_user_token', token, {path: '/'});
      this.token = token;
    },
    setUser(user) {
      this.user = user
    }
  }
})
