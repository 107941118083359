<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_603_6888)">
      <path d="M19 15L17.59 13.59L13 18.17V2H11V18.17L6.41 13.58L5 15L12 22L19 15Z"/>
    </g>
    <defs>
      <clipPath id="clip0_603_6888">
        <rect width="24" height="24"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "ArrowSouthIcon"
}
</script>

<style scoped lang="scss">
svg {
  vertical-align: top;
  width: 2.4rem;
  height: auto;
}
</style>
